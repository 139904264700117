<template>
  <b-form-group
      label="Shipping country"
      label-for="input-formatter"
      description="Shipped from Paris, France"
  >
    <b-form-select v-model="selected" class="mb-3" v-on:input="onInput" id="input-formatter">
      <template #first>
        <b-form-select-option :value="null" disabled>-- Please select a country --</b-form-select-option>
      </template>
      <b-form-select-option value="AF">Afghanistan</b-form-select-option>
      <b-form-select-option value="AL">Albania</b-form-select-option>
      <b-form-select-option value="DZ">Algeria</b-form-select-option>
      <b-form-select-option value="AD">Andorra</b-form-select-option>
      <b-form-select-option value="AO">Angola</b-form-select-option>
      <b-form-select-option value="AQ">Antarctica</b-form-select-option>
      <b-form-select-option value="AR">Argentina</b-form-select-option>
      <b-form-select-option value="AM">Armenia</b-form-select-option>
      <b-form-select-option value="AU">Australia</b-form-select-option>
      <b-form-select-option value="AT">Austria</b-form-select-option>
      <b-form-select-option value="AZ">Azerbaijan</b-form-select-option>
      <b-form-select-option value="BH">Bahrain</b-form-select-option>
      <b-form-select-option value="BD">Bangladesh</b-form-select-option>
      <b-form-select-option value="BY">Belarus</b-form-select-option>
      <b-form-select-option value="BE">Belgium</b-form-select-option>
      <b-form-select-option value="BJ">Benin</b-form-select-option>
      <b-form-select-option value="BO">Bolivia</b-form-select-option>
      <b-form-select-option value="BA">Bosnia and Herzegovina</b-form-select-option>
      <b-form-select-option value="BR">Brazil</b-form-select-option>
      <b-form-select-option value="IO">British Indian Ocean Territory</b-form-select-option>
      <b-form-select-option value="VG">British Virgin Islands</b-form-select-option>
      <b-form-select-option value="BN">Brunei</b-form-select-option>
      <b-form-select-option value="BG">Bulgaria</b-form-select-option>
      <b-form-select-option value="BF">Burkina Faso</b-form-select-option>
      <b-form-select-option value="BI">Burundi</b-form-select-option>
      <b-form-select-option value="KH">Cambodia</b-form-select-option>
      <b-form-select-option value="CM">Cameroon</b-form-select-option>
      <b-form-select-option value="CA">Canada</b-form-select-option>
      <b-form-select-option value="CF">Central African Republic</b-form-select-option>
      <b-form-select-option value="TD">Chad</b-form-select-option>
      <b-form-select-option value="CL">Chile</b-form-select-option>
      <b-form-select-option value="CN">China</b-form-select-option>
      <b-form-select-option value="CO">Colombia</b-form-select-option>
      <b-form-select-option value="KM">Comoros</b-form-select-option>
      <b-form-select-option value="CK">Cook Islands</b-form-select-option>
      <b-form-select-option value="CR">Costa Rica</b-form-select-option>
      <b-form-select-option value="HR">Croatia</b-form-select-option>
      <b-form-select-option value="CW">Curaçao</b-form-select-option>
      <b-form-select-option value="CY">Cyprus</b-form-select-option>
      <b-form-select-option value="CZ">Czech Republic</b-form-select-option>
      <b-form-select-option value="CD">DR Congo</b-form-select-option>
      <b-form-select-option value="DK">Denmark</b-form-select-option>
      <b-form-select-option value="DJ">Djibouti</b-form-select-option>
      <b-form-select-option value="EG">Egypt</b-form-select-option>
      <b-form-select-option value="SV">El Salvador</b-form-select-option>
      <b-form-select-option value="GQ">Equatorial Guinea</b-form-select-option>
      <b-form-select-option value="ER">Eritrea</b-form-select-option>
      <b-form-select-option value="EE">Estonia</b-form-select-option>
      <b-form-select-option value="ET">Ethiopia</b-form-select-option>
      <b-form-select-option value="FK">Falkland Islands</b-form-select-option>
      <b-form-select-option value="FO">Faroe Islands</b-form-select-option>
      <b-form-select-option value="FJ">Fiji</b-form-select-option>
      <b-form-select-option value="FI">Finland</b-form-select-option>
      <b-form-select-option value="FR">France</b-form-select-option>
      <b-form-select-option value="GF">French Guiana</b-form-select-option>
      <b-form-select-option value="PF">French Polynesia</b-form-select-option>
      <b-form-select-option value="TF">French Southern and Antarctic Lands</b-form-select-option>
      <b-form-select-option value="GA">Gabon</b-form-select-option>
      <b-form-select-option value="GE">Georgia</b-form-select-option>
      <b-form-select-option value="DE">Germany</b-form-select-option>
      <b-form-select-option value="GH">Ghana</b-form-select-option>
      <b-form-select-option value="GI">Gibraltar</b-form-select-option>
      <b-form-select-option value="GR">Greece</b-form-select-option>
      <b-form-select-option value="GL">Greenland</b-form-select-option>
      <b-form-select-option value="GP">Guadeloupe</b-form-select-option>
      <b-form-select-option value="GU">Guam</b-form-select-option>
      <b-form-select-option value="GT">Guatemala</b-form-select-option>
      <b-form-select-option value="GG">Guernsey</b-form-select-option>
      <b-form-select-option value="GN">Guinea</b-form-select-option>
      <b-form-select-option value="HN">Honduras</b-form-select-option>
      <b-form-select-option value="HK">Hong Kong</b-form-select-option>
      <b-form-select-option value="HU">Hungary</b-form-select-option>
      <b-form-select-option value="IS">Iceland</b-form-select-option>
      <b-form-select-option value="IN">India</b-form-select-option>
      <b-form-select-option value="ID">Indonesia</b-form-select-option>
      <b-form-select-option value="IQ">Iraq</b-form-select-option>
      <b-form-select-option value="IE">Ireland</b-form-select-option>
      <b-form-select-option value="IM">Isle of Man</b-form-select-option>
      <b-form-select-option value="IL">Israel</b-form-select-option>
      <b-form-select-option value="IT">Italy</b-form-select-option>
      <b-form-select-option value="CI">Ivory Coast</b-form-select-option>
      <b-form-select-option value="JP">Japan</b-form-select-option>
      <b-form-select-option value="JE">Jersey</b-form-select-option>
      <b-form-select-option value="JO">Jordan</b-form-select-option>
      <b-form-select-option value="KZ">Kazakhstan</b-form-select-option>
      <b-form-select-option value="KE">Kenya</b-form-select-option>
      <b-form-select-option value="KI">Kiribati</b-form-select-option>
      <b-form-select-option value="KW">Kuwait</b-form-select-option>
      <b-form-select-option value="KG">Kyrgyzstan</b-form-select-option>
      <b-form-select-option value="LV">Latvia</b-form-select-option>
      <b-form-select-option value="LB">Lebanon</b-form-select-option>
      <b-form-select-option value="LS">Lesotho</b-form-select-option>
      <b-form-select-option value="LI">Liechtenstein</b-form-select-option>
      <b-form-select-option value="LT">Lithuania</b-form-select-option>
      <b-form-select-option value="LU">Luxembourg</b-form-select-option>
      <b-form-select-option value="MO">Macau</b-form-select-option>
      <b-form-select-option value="MK">Macedonia</b-form-select-option>
      <b-form-select-option value="MG">Madagascar</b-form-select-option>
      <b-form-select-option value="MY">Malaysia</b-form-select-option>
      <b-form-select-option value="MV">Maldives</b-form-select-option>
      <b-form-select-option value="ML">Mali</b-form-select-option>
      <b-form-select-option value="MT">Malta</b-form-select-option>
      <b-form-select-option value="MQ">Martinique</b-form-select-option>
      <b-form-select-option value="MU">Mauritius</b-form-select-option>
      <b-form-select-option value="YT">Mayotte</b-form-select-option>
      <b-form-select-option value="MX">Mexico</b-form-select-option>
      <b-form-select-option value="MD">Moldova</b-form-select-option>
      <b-form-select-option value="MC">Monaco</b-form-select-option>
      <b-form-select-option value="ME">Montenegro</b-form-select-option>
      <b-form-select-option value="MA">Morocco</b-form-select-option>
      <b-form-select-option value="NA">Namibia</b-form-select-option>
      <b-form-select-option value="NR">Nauru</b-form-select-option>
      <b-form-select-option value="NP">Nepal</b-form-select-option>
      <b-form-select-option value="NL">Netherlands</b-form-select-option>
      <b-form-select-option value="NC">New Caledonia</b-form-select-option>
      <b-form-select-option value="NZ">New Zealand</b-form-select-option>
      <b-form-select-option value="NE">Niger</b-form-select-option>
      <b-form-select-option value="NG">Nigeria</b-form-select-option>
      <b-form-select-option value="MP">Northern Mariana Islands</b-form-select-option>
      <b-form-select-option value="NO">Norway</b-form-select-option>
      <b-form-select-option value="OM">Oman</b-form-select-option>
      <b-form-select-option value="PK">Pakistan</b-form-select-option>
      <b-form-select-option value="PA">Panama</b-form-select-option>
      <b-form-select-option value="PG">Papua New Guinea</b-form-select-option>
      <b-form-select-option value="PH">Philippines</b-form-select-option>
      <b-form-select-option value="PN">Pitcairn Islands</b-form-select-option>
      <b-form-select-option value="PL">Poland</b-form-select-option>
      <b-form-select-option value="PT">Portugal</b-form-select-option>
      <b-form-select-option value="QA">Qatar</b-form-select-option>
      <b-form-select-option value="CG">Republic of the Congo</b-form-select-option>
      <b-form-select-option value="RO">Romania</b-form-select-option>
      <b-form-select-option value="RU">Russia</b-form-select-option>
      <b-form-select-option value="RW">Rwanda</b-form-select-option>
      <b-form-select-option value="RE">Réunion</b-form-select-option>
      <b-form-select-option value="BL">Saint Barthélemy</b-form-select-option>
      <b-form-select-option value="SH">Saint Helena</b-form-select-option>
      <b-form-select-option value="MF">Saint Martin</b-form-select-option>
      <b-form-select-option value="PM">Saint Pierre and Miquelon</b-form-select-option>
      <b-form-select-option value="VC">Saint Vincent and the Grenadines</b-form-select-option>
      <b-form-select-option value="WS">Samoa</b-form-select-option>
      <b-form-select-option value="SM">San Marino</b-form-select-option>
      <b-form-select-option value="SA">Saudi Arabia</b-form-select-option>
      <b-form-select-option value="SN">Senegal</b-form-select-option>
      <b-form-select-option value="RS">Serbia</b-form-select-option>
      <b-form-select-option value="SG">Singapore</b-form-select-option>
      <b-form-select-option value="SK">Slovakia</b-form-select-option>
      <b-form-select-option value="SI">Slovenia</b-form-select-option>
      <b-form-select-option value="SB">Solomon Islands</b-form-select-option>
      <b-form-select-option value="ZA">South Africa</b-form-select-option>
      <b-form-select-option value="GS">South Georgia</b-form-select-option>
      <b-form-select-option value="KR">South Korea</b-form-select-option>
      <b-form-select-option value="SS">South Sudan</b-form-select-option>
      <b-form-select-option value="ES">Spain</b-form-select-option>
      <b-form-select-option value="LK">Sri Lanka</b-form-select-option>
      <b-form-select-option value="SR">Suriname</b-form-select-option>
      <b-form-select-option value="SZ">Swaziland</b-form-select-option>
      <b-form-select-option value="SE">Sweden</b-form-select-option>
      <b-form-select-option value="CH">Switzerland</b-form-select-option>
      <b-form-select-option value="ST">São Tomé and Príncipe</b-form-select-option>
      <b-form-select-option value="TW">Taiwan</b-form-select-option>
      <b-form-select-option value="TJ">Tajikistan</b-form-select-option>
      <b-form-select-option value="TZ">Tanzania</b-form-select-option>
      <b-form-select-option value="TH">Thailand</b-form-select-option>
      <b-form-select-option value="TL">Timor-Leste</b-form-select-option>
      <b-form-select-option value="TG">Togo</b-form-select-option>
      <b-form-select-option value="TO">Tonga</b-form-select-option>
      <b-form-select-option value="TT">Trinidad and Tobago</b-form-select-option>
      <b-form-select-option value="TN">Tunisia</b-form-select-option>
      <b-form-select-option value="TR">Turkey</b-form-select-option>
      <b-form-select-option value="TM">Turkmenistan</b-form-select-option>
      <b-form-select-option value="TV">Tuvalu</b-form-select-option>
      <b-form-select-option value="UG">Uganda</b-form-select-option>
      <b-form-select-option value="UA">Ukraine</b-form-select-option>
      <b-form-select-option value="AE">United Arab Emirates</b-form-select-option>
      <b-form-select-option value="GB">United Kingdom</b-form-select-option>
      <b-form-select-option value="US">United States</b-form-select-option>
      <b-form-select-option value="VU">Vanuatu</b-form-select-option>
      <b-form-select-option value="VA">Vatican City</b-form-select-option>
      <b-form-select-option value="VN">Vietnam</b-form-select-option>
      <b-form-select-option value="WF">Wallis and Futuna</b-form-select-option>
      <b-form-select-option value="ZM">Zambia</b-form-select-option>
      <b-form-select-option value="ZW">Zimbabwe</b-form-select-option>
      <b-form-select-option value="AX">Åland Islands</b-form-select-option>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "CountrySelector",
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    onInput(selected){
      this.$emit('input',selected)
    }
  }
}
</script>

<style scoped>

</style>