<template>
  <div>
    <div v-for="item in items" :key="item.sku" class="mb-2">
      <b-row>
        <b-col cols="3" class="no-gutters">
          <b-img thumbnail :src="item.images[0].url" :alt="item.title" fluid></b-img>
        </b-col>
        <b-col>
          <h4>{{ item.title }}</h4>
          <p>Unit Price: {{ item.unitPriceVATInc.display }}</p>
          <h5>{{ item.totalPriceVATInc.display }}</h5>
          <div v-if="updatable === true">
            Quantity:
            <b-button variant="danger" @click="updateItem(item, -1)" :disabled="alreadyInUpdate[item.id]">
              <b-spinner v-if="alreadyInUpdate[item.id]" small></b-spinner>
              -
            </b-button>
            {{item.quantity}}
            <b-button variant="success" @click="updateItem(item, 1)" :disabled="alreadyInUpdate[item.id]">
              <b-spinner v-if="alreadyInUpdate[item.id]" small></b-spinner>
              +
            </b-button>

          </div>
          <div v-else>
            Quantity: {{item.quantity}}
          </div>
          <b-button v-if="updatable" variant="outline-danger" @click="updateItem(item,-item.quantity)" class="mr-2 mt-5">Remove</b-button>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import _ from "lodash";

export default {
  name: "CartView",
  props: {
    items: Object,
    updatable: Boolean
  },
  data() {
    return {
      itemsCloned: null,
      showSpinner: false,
      alreadyInUpdate: {},
    }
  },
  created() {
    this.cloneItems()
  },
  methods: {
    cloneItems() {
      this.itemsCloned = _.cloneDeep(this.items)

    },
    updateItem(item, delta) {
      if (this.alreadyInUpdate[item.id]) {
        return
      }
      this.alreadyInUpdate[item.id] = true
      this.loading = true
      AuthHelper.getOrCreateAnonUser().then((user) => {
        Webservice.addToCart(item.id,delta,user.idToken).then(() => {
          this.$emit('cart-updated')
          this.cloneItems()
          this.makeToast("Success", "Cart updated !", "success")
          this.alreadyInUpdate[item.id] = false
          this.loading = false
        }).catch((err) => {
          console.log("err while update cart" + err)
          this.alreadyInUpdate[item.id] = false
          this.loading = false
        })
      }).catch((err) => {
        console.log("err while get user")
        console.log(err)
        this.alreadyInUpdate = false
        this.loading = false
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>